import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "@rdxmodules/enrollment/actionTypes";

import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { Form, Col, Row } from "antd";
import { Notifier } from "@airbrake/browser";

import useLoading from "@loading";

import Enrollment from "models/Enrollment";
import MessageEvent from "models/MessageEvent";

import GradientButton from "components/Buttons/GradientButton";

import TermsOfService from "./TermsOfService";

import styles from "./PaymentInfo.module.less";

const StripeForm = ({
  service,
  session,
  addressForm,
  updateProfile,
  onStripeSubmitComplete,
  latestMessage,
  enrollmentIntent,
  setAlertMessageVisible,
}) => {
  const [stripeForm] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [enrollmentSubmitted, setEnrollmentSubmitted] = useState(false);
  const [formValid, setFormValid] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });
  const [isTosChecked, setIsTosChecked] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const reportStripeConnection = () => {
    const url1 = "https://js.stripe.com/v3/fingerprinted/js/elements-inner-card-f168cb5e237dc04a5625532edb08f52d.js";
    const url2 = "https://js.stripe.com/v3/elements-inner-card-a9aced728a05f6181299044ffc6450b8.html";

    const creds = {
      projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
      projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
      environment: process.env.REACT_APP_ENV,
    };

    fetch(url1).catch((e) => {
      new Notifier(creds).notify({ e, params: { user_id: session.user.props.id, url: 1 } });
    });

    fetch(url2).catch((e) => {
      new Notifier(creds).notify({ e, params: { user_id: session.user.props.id, url: 2 } });
    });
  };

  useEffect(() => {
    if (service.slug) {
      enrollmentIntent({ service: service.slug });
    }

    reportStripeConnection();
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (change) => {
    const { complete, elementType } = change;
    setFormValid({ ...formValid, [elementType]: complete });
  };

  const loading = useLoading({
    watchRequests: [types.SUBSCRIBE_ENROLLMENT],
  });

  useEffect(() => {
    const validated = formValid.cardNumber && formValid.cardCvc && formValid.cardExpiry && isTosChecked;
    setSubmitDisabled(!validated);
  }, [formValid, isTosChecked]);

  useEffect(() => {
    if (enrollmentSubmitted && !loading) {
      onStripeSubmitComplete(latestMessage.message);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const postStripe = () => {
    const cardElement = elements.getElement(CardNumberElement);
    return stripe.createToken(cardElement);
  };

  const handlePaymentFormSubmission = () => {
    addressForm
      .validateFields()
      .then((values) => {
        const preparedUser = session.user.preparedForRequest();

        updateProfile({ user: preparedUser.user, address: values });
        setAlertMessageVisible({
          message: "Processing. . .",
          severity: "info",
          duration: 6000,
        });
        postStripe().then((res) => {
          if (!res.error) {
            setEnrollmentSubmitted(true);
            const userEnrollment = new Enrollment(session.user.props.enrollment);
            const { paymentStatus } = userEnrollment?.props || {};

            if (paymentStatus === "canceling") {
              userEnrollment.renew(service.slug);
            } else {
              Enrollment.post(res.token, true, service.slug);
            }
          } else {
            console.warn(res.error);
            setAlertMessageVisible({
              message: res.error.status === "bad_request" ? res.error.message : `Error subscribing to ${service.name}`,
              severity: "error",
            });
          }
        });
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#295CA0",
        fontWeight: "500",
        fontSize: "16px",
        "::placeholder": {
          color: "#8499B7",
        },
      },
    },
  };

  const handleTos = () => {
    setIsTosChecked((isChecked) => !isChecked);
  };

  return (
    <div className={styles.stripeFormContainer}>
      <Form form={stripeForm} onFinish={handlePaymentFormSubmission}>
        <Row>
          <Col span={24}>
            <span className={styles.inputLabel}>Card Number</span>
            <Form.Item name="card" rules={[{ required: true, message: "Card Number is required." }]}>
              <CardNumberElement
                options={CARD_ELEMENT_OPTIONS}
                className={styles.stripeInput}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <span className={styles.inputLabel}>Expiration</span>
            <Form.Item name="exp">
              <CardExpiryElement
                options={CARD_ELEMENT_OPTIONS}
                className={styles.stripeInput}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <span className={styles.inputLabel}>CVC</span>
            <Form.Item name="cvc">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} className={styles.stripeInput} onChange={handleChange} />
            </Form.Item>
          </Col>
        </Row>
        <TermsOfService handleTos={handleTos} onSubmit="handlePaymentFormSubmission" />
        <div className={styles.buttonContainer}>
          <GradientButton className={styles.purchaseButton} disabled={submitDisabled} type="primary" htmlType="submit">
            Purchase
          </GradientButton>
        </div>
      </Form>
    </div>
  );
};

StripeForm.propTypes = {
  service: PropTypes.shape({
    fee: PropTypes.number,
    id: PropTypes.number,
    monthly: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  onStripeSubmitComplete: PropTypes.func,
  session: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  addressForm: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  updateProfile: PropTypes.func,
  latestMessage: MessageEvent.types(),
  enrollmentIntent: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    activeRequests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper(["updateProfile", "enrollmentIntent", "setAlertMessageVisible"]),
)(StripeForm);
