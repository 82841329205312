import types from "rdx/modules/enrollment/actionTypes";
import createAction from "rdx/utils/createAction";

export default {
  setUpgradePreview: (payload) => createAction(types.SET_UPGRADE_PREVIEW, payload),
  getUpgradePreview: (payload) => createAction(types.GET_UPGRADE_PREVIEW, payload),
  upgradeEnrollment: (payload) => createAction(types.UPGRADE_ENROLLMENT, payload),
  downgradeEnrollment: (payload) => createAction(types.DOWNGRADE_ENROLLMENT, payload),
  getEnrollments: (payload) => createAction(types.GET_ENROLLMENTS, payload),
  setEnrollments: (payload) => createAction(types.SET_ENROLLMENTS, payload),
  setServices: (payload) => createAction(types.SET_SERVICES, payload),
  setMonthlyTotal: (payload) => createAction(types.SET_MONTHLY_TOTAL, payload),
  setCredit: (payload) => createAction(types.SET_CREDIT, payload),
  setCardInfo: (payload) => createAction(types.SET_CARD_INFO, payload),
  setSelectedEnrollment: (payload) => createAction(types.SET_SELECTED_ENROLLMENT, payload),
  setDowngradeTo: (payload) => createAction(types.SET_DOWNGRADE_TO, payload),
  getCharges: (payload) => createAction(types.GET_CHARGES, payload),
  setCharges: (payload) => createAction(types.SET_CHARGES, payload),
  openEditEnrollmentModal: (payload) => createAction(types.OPEN_EDIT_ENROLLMENT_MODAL, payload),
  closeEditEnrollmentModal: (payload) => createAction(types.CLOSE_EDIT_ENROLLMENT_MODAL, payload),

  renewEnrollment: (payload) => createAction(types.RENEW_ENROLLMENT, payload),

  cancelEnrollment: (payload) => createAction(types.CANCEL_ENROLLMENT, payload),

  subscribeEnrollment: (payload) => createAction(types.SUBSCRIBE_ENROLLMENT, payload),

  enrollmentIntent: (payload) => createAction(types.ENROLLMENT_INTENT, payload),
  // INSERTION_PT (for script, do not remove)
};
