import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { Grid, GridProps } from "@mui/material";
import { startCase, toLower } from "lodash";
import { SwitcherController, CopyToClipBoard } from "@mui-styled-components/index";
import { Control, useForm } from "react-hook-form";
import copy from "copy-to-clipboard";

import { formatPhoneNumber } from "lib/helpers";
import type { AvatarT } from "types/utils";
import { actions } from "rdx";

import { cvar } from "styles";
import { HomeownerIconLarge } from "components/Icons";
import { Avatar } from "../AutoComplete/Avatar";
import { UserBasicInfoWrapper, UserNameText, UserDetailText, LightTooltip } from "./styles";
import { isUndefined } from "../../../types/guards";

type TAction = {
  control?: Control;
  handleAction: (val: MouseEvent<HTMLElement> | string | boolean | null) => void;
  defaultValue?: string;
};

type TTextAction = {
  actionText?: string;
  handleAction: () => void;
};

type EmailT = { value: string | undefined; allowToCopy?: boolean };

export type UserBasicInfoAvatarT = AvatarT & { defaultAvatarWidth?: string; avatarWidth?: string };

type BaseUserBasicInfoProps = {
  userId: number | undefined;
  fullName?: string;
  phone?: string | null;
  email?: EmailT | null;
  userType?: string;
  tooltip?: React.ReactNode | null;
  extraContent?: React.ReactNode | null;
  textAction?: TTextAction;
  controlAction?: TAction;
  avatar?: UserBasicInfoAvatarT;
  containerRow?: GridProps;
  items?: { avatarItem?: GridProps; contentItem?: GridProps };
};

type WithLeadId = {
  leadId: number;
  handleAvatarClick: (e: Event, leadId: number, userRole?: string) => void;
};

type WithoutLeadId = {
  leadId?: undefined;
  handleAvatarClick?: undefined;
};

type TUserBasicInfoComponent = BaseUserBasicInfoProps & (WithLeadId | WithoutLeadId);

const UserBasicInfoComponent = ({
  userId,
  leadId,
  fullName,
  email,
  phone,
  userType,
  avatar,
  tooltip = null,
  textAction,
  controlAction,
  containerRow,
  extraContent,
  items = { avatarItem: { padding: 1 }, contentItem: { padding: 1 } },
  handleAvatarClick,
}: TUserBasicInfoComponent) => {
  const { defaultAvatarWidth, avatarWidth, thumb } = avatar || {};
  const dispatch = useDispatch();

  const { control: defaultControl } = useForm();

  const handleCopyAction = (value: string | undefined) => {
    if (!isUndefined(value)) {
      copy(value);
      dispatch(
        actions.setAlertMessageVisible({
          message: (
            <span>
              <strong>{value}</strong> copied to the clipboard
            </span>
          ),
          severity: "success",
        }),
      );
    }
  };

  const formattedUserType = startCase(toLower(userType));
  return (
    <>
      <LightTooltip arrow title={tooltip} placement="top-start">
        <UserBasicInfoWrapper>
          <Grid
            direction="row"
            justifyContent="center"
            flexWrap="nowrap"
            container
            alignItems="center"
            maxWidth="100%"
            {...containerRow}
          >
            <Grid
              item
              {...items.avatarItem}
              onClick={(e) => handleAvatarClick?.(e as unknown as Event, leadId, userType)}
            >
              {formattedUserType === "Homeowner" ? (
                <HomeownerIconLarge width="32" height="32" fill={cvar("blue-ribbon")} />
              ) : (
                <Avatar
                  type="user"
                  avatarContent={(thumb as string) || null}
                  avatarWidth={avatarWidth}
                  defaultAvatarWidth={defaultAvatarWidth}
                  borderRadius="24px"
                />
              )}
            </Grid>
            <Grid item maxWidth="calc(100% - 48px)" {...items.contentItem} paddingRight={tooltip ? "0px" : "12px"}>
              {formattedUserType && (
                <UserDetailText allCaps textSize={12} dark noMarginTop>
                  {formattedUserType}
                </UserDetailText>
              )}
              {fullName && (
                <UserNameText>
                  {fullName} ({userId})
                </UserNameText>
              )}
              {phone && (
                <UserDetailText onClick={() => handleCopyAction(phone)} textSize={16} lineHeight={22} isAction>
                  {formatPhoneNumber(phone)}
                </UserDetailText>
              )}
              {email && (
                <UserDetailText
                  noBottomMargin
                  onClick={() => handleCopyAction(email.value)}
                  textSize={16}
                  lineHeight={22}
                  isAction
                >
                  {email.value && email.allowToCopy ? (
                    <CopyToClipBoard valueToCopy={email.value}>{email.value} </CopyToClipBoard>
                  ) : (
                    email.value
                  )}
                </UserDetailText>
              )}
              {extraContent && extraContent}
              {textAction && textAction.actionText && (
                <UserDetailText isAction onClick={textAction.handleAction}>
                  {textAction.actionText}
                </UserDetailText>
              )}
              {controlAction && (
                <SwitcherController
                  handleChange={(val) => {
                    controlAction.handleAction(val);
                  }}
                  defaultValue={controlAction.defaultValue}
                  name="pending"
                  control={controlAction.control || defaultControl}
                  label="Waiting on Seller response"
                />
              )}
            </Grid>
          </Grid>
        </UserBasicInfoWrapper>
      </LightTooltip>
    </>
  );
};

export default UserBasicInfoComponent;
