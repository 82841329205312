// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// Ant Design
import { Layout } from "antd";

// Util
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// Components
import {
  Header,
  Footer,
  VideoTestimonials,
  GetQuoteModal,
  UtilityBillModal,
  SuccessModal,
  // JoinModal, export not working for some reason, imported below
} from "components/HomepageComponents";
// import JoinModal from "components/HomepageComponents/JoinModal";
import ScrollToTop from "containers/router/scrollToTop";
import HeroImage from "./components/HeroImage";
import PowurIntroRow from "./components/PowurIntroRow";
import ContentRow from "./components/ContentRow";
import PricingAndPlans from "../enterpriseJoin/components/PricingAndPlans";

// CSS Modules
import styles from "./Join.module.less";

const { Content } = Layout;

const Join = (props) => {
  const { user } = props;
  const { t } = useTranslation("join");
  const { slug } = useParams();
  const history = useHistory();
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  const [sponsor, setSponsor] = useState(null);

  useEffect(() => {
    if (user) setSponsor(user.vanity_name);
  }, [user]);

  const handleCreateAccount = () => {
    history.push(`${sponsor ? `/${sponsor}` : ""}/signup${slug ? `/${slug}` : ""}`);
  };

  const description =
    "Powur gives the power back. See why the best solar professionals are choosing to build their future with us.";

  const testimonialCards = [
    {
      title: t("testimonials.financial_independence"),
      text: t("testimonials.financial_security"),
      imageSource: `${urlPrefix}/assets/img/join/consultant-testimonial-maria.png`,
      videoSource: `${urlPrefix}/assets/videos/Powur_Consultants_MariaSalazar_v1.mp4`,
    },
    {
      title: t("testimonials.four_freedoms"),
      text: t("testimonials.many_types_of_freedom"),
      imageSource: `${urlPrefix}/assets/img/join/consultant-testimonial-chris.png`,
      videoSource: `${urlPrefix}/assets/videos/Powur_Consultant_ChrisTshibangu_1.mp4`,
    },
    {
      title: t("testimonials.virtual_opportunity"),
      text: t("testimonials.lifestyle_and_community"),
      imageSource: `${urlPrefix}/assets/img/join/consultant-testimonial-jonb.png`,
      videoSource: `${urlPrefix}/assets/videos/Powur_Consultant_JonB_Edit_05_web_1.mp4`,
    },
  ];

  const testimonialIntro = t("testimonials.powur_stories");

  const contentData = [
    {
      imageLink: `${urlPrefix}/assets/img/join/join_MaximizePotential.jpg`,
      header: t("info_panels.trust_and_transparency"),
      subHeader: t("info_panels.maximize_your_potential"),
      paragraph: t("info_panels.powur_revolutionary_model"),
    },
    {
      imageLink: `${urlPrefix}/assets/img/join/join_WorkAnywhere.jpg`,
      header: t("info_panels.remote_and_flexible"),
      subHeader: t("info_panels.work_anywhere"),
      paragraph: t("info_panels.worlds_first_virtual_solar"),
    },
    {
      imageLink: `${urlPrefix}/assets/img/join/join_PassiveIncome.jpg`,
      header: t("info_panels.sustainable_model"),
      subHeader: t("info_panels.generate_passive_income"),
      paragraph: t("info_panels.work_life_balance"),
    },
    {
      imageLink: `${urlPrefix}/assets/img/join/join_equity-ownership-bg.jpg`,
      header: t("info_panels.equity_ownership"),
      subHeader: t("info_panels.invest_in_your_future"),
      paragraph: t("info_panels.industry_first_equity_ownership_program"),
    },
    {
      imageLink: `${urlPrefix}/assets/img/join/join_streamlined-technology-bg.png`,
      header: t("info_panels.future_proof"),
      subHeader: t("info_panels.innovate_with_us"),
      paragraph: t("info_panels.proprietary_technology"),
    },
  ];

  return (
    <>
      <Layout className={styles.join}>
        <Helmet>
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
          <title>Join Powur</title>
          <meta name="title" content="Join Powur" />
          <meta name="description" content="Access training and start your solar sales journey in just minutes." />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://powur.com/join" />
          <meta property="og:title" content="The Best Solar Professionals are building their future with Powur" />
          <meta
            property="og:description"
            content="Our cloud-based solar sales and fulfillment platform provides you with the training, tools, and mentorship that you need, to reach your professional goals."
          />
          <meta property="og:image" content="/img/powur_fb_01.jpg" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://powur.com/join" />
          <meta property="twitter:title" content="The Best Solar Professionals are building their future with Powur" />
          <meta
            property="twitter:description"
            content="Our cloud-based solar sales and fulfillment platform provides you with the training, tools, and mentorship that you need, to reach your professional goals."
          />
          <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
        </Helmet>
        <Header user={user} />
        <ScrollToTop />
        <Content className={styles.contentContainer}>
          <HeroImage handleCreateAccount={handleCreateAccount} />
          {contentData.map((d, i) => {
            return (
              <ContentRow
                header={d.header}
                subHeader={d.subHeader}
                paragraph={d.paragraph}
                imageLink={d.imageLink}
                idx={i}
                key={d.header}
              />
            );
          })}
          <VideoTestimonials
            backgroundColor="var(--cultured-white)"
            cardInfo={testimonialCards}
            testimonialIntro={testimonialIntro}
          />
          <div className={styles.extraMargin} />
          <PowurIntroRow handleCreateAccount={handleCreateAccount} />
          <PricingAndPlans handleCreateAccount={handleCreateAccount} />
        </Content>
        <Footer />
        <GetQuoteModal />
        <UtilityBillModal />
        <SuccessModal />
      </Layout>
    </>
  );
};

export default Join;
