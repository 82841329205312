import z from "zod";
import { AgentSchema, CoordinatorSchema, MentorSchema, AmbassadorSchema } from "rdx/modules/users/schemas/users";
import { ContractSchema } from "../../contracts";
import {
  RoofingProposalSchema,
  SoloProposalSchema,
  TpoPricingWorksheetSchema,
  ProposalNonRequestableReasonSchema,
  RoofingNonRequestableReasonSchema,
  VisionProposalPartialSchema,
} from "./proposals";
import { ActivityLogDataSchema, CrmActivitySchema } from "../pre-sales";
import { TeamSchema } from "./team";
import { LeadSchema } from "./lead";

const FinanceOptionsSchema = z.object({
  productTypes: z.array(z.string()),
  financeProducts: z.array(
    z.object({
      id: z.number(),
      productLabel: z.string().nullable(),
      productType: z.string().nullable(),
      tooltipText: z.string().nullable(),
      options: z.object({
        id: z.number(),
        proposalFinanceProductId: z.number(),
        stateAbbr: z.string(),
        archivedAt: z.string().nullable(),
        escalators: z.array(z.string()),
        loanLength: z.array(z.string()),
        interestRate: z.array(z.string()),
        createdAt: z.string(),
        updatedAt: z.string(),
        productDescription: z.string().nullable(),
      }),
    }),
  ),
});

export const ProjectSchema = z.object({
  id: z.number(),
  roofCheck: z.boolean(),
  roofCheckNotes: z.nullable(z.string()),
  vssRequest: z.boolean(),
  kwhConsumption: z.string(),
  proposalRequestAt: z.nullable(z.string()),
  solarProposalRequested: z.boolean(),
  closeReasonId: z.nullable(z.number()),
  submitAt: z.nullable(z.string()),
  closeAt: z.nullable(z.string()),
  createdAt: z.string(),
  updatedAt: z.string(),
  installerId: z.nullable(z.number()),
  proposalUrl: z.nullable(z.string()),
  lastMessageAt: z.nullable(z.string()),
  workNeededDepr: z.nullable(z.string()),
  m1At: z.string().nullable(),
  m2At: z.string().nullable(),
  commissions: z.number().nullable(),
  showAltCommissionsText: z.boolean(),
  installationNotes: z.nullable(z.string()),
  otherDetailsDepr: z.array(z.number()),
  visionProposal: VisionProposalPartialSchema,
  generatorDetails: z.nullable(z.string()),
  generatorOnSite: z.nullable(z.boolean()),
  constructionDetails: z.object({}),
  requiresRsm: z.boolean(),
  hoaName: z.nullable(z.string()),
  hoaPhone: z.string(),
  mounting: z.union([z.literal("ground"), z.literal("roof"), z.literal("both")]),
  utilityCompany: z.string().nullable(),
  financing: z.union([z.literal("cash"), z.literal("loan"), z.literal("lease")]),
  workType: z.string().nullable(),
  otherDetails: z.array(z.string()),
  roofAge: z.string(),
  roofType: z.union([
    z.literal("standard_asphalt"),
    z.literal("flat_roof"),
    z.literal("concrete_tile"),
    z.literal("concrete"),
    z.literal("steel_shingle"),
    z.literal("metal"),
    z.literal("other"),
  ]),
  servicePanel: z.union([
    z.literal("amp_100"),
    z.literal("amp_125"),
    z.literal("amp_150"),
    z.literal("amp_200"),
    z.literal("amp_400"),
  ]),
  homeownerId: z.number(),
  offerable: z.boolean(),
  internal: z.boolean(),
  apiUri: z.string(),
  agent: AgentSchema,
  mentor: MentorSchema.nullish(),
  coordinator: CoordinatorSchema.nullish(),
  ambassador: AmbassadorSchema.nullish(),
  team: TeamSchema.nullish(),
  latestProposal: SoloProposalSchema.nullish(),
  latestProposalRejection: z.string().nullable(),
  existingSystem: z.object({}),
  proposalNonrequestableReason: ProposalNonRequestableReasonSchema.nullable(),
  mentorRequirementMet: z.boolean(),
  qualifyComplete: z.boolean(),
  userCanRequestProposal: z.boolean(),
  closeNote: z.string().nullable(),
  visionProposalNonrequestableReason: ProposalNonRequestableReasonSchema.or(
    z.union([z.literal("already_requested"), z.literal("outside_vision_regions")]),
  ).nullable(),
  soloProposalNonrequestableReason: ProposalNonRequestableReasonSchema.or(z.literal("outside_solo_regions")).nullable(),
  lightreachProposalNonrequestableReason: ProposalNonRequestableReasonSchema.or(
    z.union([z.literal("outside_lightreach_regions"), z.literal("lightreach_already_requested")]),
  ).nullable(),
  canRequestProposal: z.boolean(),
  projectProgress: z.array(z.string()),
  projectAtAGlance: z.object({
    actualStatus: z.string(),
    changeOrderReasons: z.array(z.string()).nullable(),
    lastMilestoneCompleted: z.string(),
    milestoneStatus: z.string(),
    nextMilestoneToComplete: z.string().nullable(),
    projectNotifications: z.array(
      z.object({
        projectId: z.number(),
        reasonCodeId: z.number(),
        message: z.string(),
        osCreatedAt: z.string(),
        kind: z.string(),
      }),
    ),
    projectStatus: z.string(),
    reasons: z.array(z.string()).nullable(),
  }),
  roofingNonRequestableReason: RoofingNonRequestableReasonSchema,
  ticketsCount: z.number(),
  canRequestRoofingProposal: z.boolean(),
  canSubmitOrReviseRoofing: z.boolean(),
  stage: z.string(),
  latestRoofingProposal: RoofingProposalSchema.nullable(),
  canRequestSunnovaProposal: z.boolean(),
  sunnovaNonRequestableReason: z
    .union([
      z.literal("certification_needed"),
      z.literal("proposal_needed"),
      z.literal("sunnova_in_progress"),
      z.literal("outside_sunnova_regions"),
    ])
    .nullable(),
  sunnovaFinanceOptions: FinanceOptionsSchema,
  lead: LeadSchema,
  utilities: z.array(
    z.object({
      id: z.number(),
      displayName: z.string(),
      externalName: z.string(),
      state: z.string().nullable(),
      active: z.boolean(),
      createdAt: z.string(),
      updatedAt: z.string(),
    }),
  ),
  crmActivities: z.array(CrmActivitySchema),
  showMilestoneTab: z.boolean(),
  podioData: z.object({
    externalFields: z.array(z.unknown()),
    markupFields: z.array(z.unknown()),
    pcNotes: z.string().nullable(),
  }),
  powurOsData: z
    .object({
      milestones_history: z.array(
        z.object({
          _id: z.object({ $oid: z.string() }),
          createdDateTime: z.string(),
          milestone: z.string(),
          stageId: z.number(),
          status: z.string(),
          stuckReasons: z.array(z.object({ reasonCode: z.string() })),
        }),
      ),
      milestones: z.array(z.object({ id: z.number(), value: z.string(), label: z.string() })),
      notes: z.array(
        z.object({
          id: z.number(),
          value: z.string(),
          created_at: z.string(),
          isPublic: z.boolean(),
        }),
      ),
      contract: ContractSchema,
      systemSize: z.number(),
      fundingType: z.string(),
      isTpo: z.boolean(),
      additionalWork: z.array(z.string()),
      on_hold_reasons: z.array(
        z.object({
          _id: z.object({ $oid: z.string() }),
          createdDateTime: z.string(),
          holdExpiration: z.string().nullable(),
          name: z.string(),
          type: z.string(),
        }),
      ),
      interConnectionSubmittedToUtility: z.string().nullable(),
      interConnectionApprovedByUtility: z.string().nullable(),
      data_hidden: z.boolean(),
      M1_commission_Sheet: z.object({
        _id: z.object({ $oid: z.string() }),
        approval_date: z.string().nullable(),
        historyOfRevenue: z.array(
          z.object({
            revenue: z.number(),
            createdDateTime: z.string(),
            availableCommission: z.number(),
            recalcTriggeredFrom: z.string(),
          }),
        ),
        issues: z.array(z.unknown()),
        lineItems: z.array(
          z.object({
            id: z.number(),
            cost: z.number(),
            name: z.string(),
            sort: z.number(),
            type: z.number(),
          }),
        ),
        type: z.string(),
        updated_at: z.string(),
      }),
      siteSurvey: z.object({
        id: z.number(),
        use_vss: z.string(),
        projectId: z.number(),
        vss_status: z.string(),
        vss_onsite_reason: z.string(),
      }),
    })
    .nullish(),
  preSalesMilestones: z.object({
    leadSubmitted: z.string().nullable(),
    leadQualified: z.string().nullable(),
    proposalGenerated: z.string().nullable(),
    proposalPresented: z.string().nullable(),
    contractOut: z.string().nullable(),
  }),
  postSalesMilestones: z.array(z.object({ label: z.string(), value: z.string(), key: z.number() })),
  milestone: z.string(),
  projectType: z.union([z.literal("pre_sales"), z.literal("post_sales")]),
  upcomingStageNames: z.array(z.string()),
  projectAtGlance: z.object({
    projectStatus: z.string().nullable(),
    changeOrderReasons: z.string().nullable(),
    lastMilestoneCompleted: z.string().nullable(),
    nextMilestoneToComplete: z.string().nullable(),
    milestoneStatus: z.string().nullable(),
    reasons: z.array(z.string()),
    actualStatus: z.string().nullable(),
    projectNotifications: z.array(z.object({ value: z.string(), reason: z.string(), createdAt: z.string() })),
  }),
  enterprise: z
    .object({
      id: z.number(),
      enrollmentId: z.number(),
      enterpriseSettingId: z.number(),
      name: z.string(),
      createdAt: z.string(),
      updatedAt: z.string(),
      enterpriseVolumeTierId: z.number().nullable(),
      external: z.boolean(),
      enterpriseLogo: z.string().nullable(),
      enterpriseLogoUrlS3Data: z.object({}),
      revenueBonusPercentage: z.number().nullable(),
      selfFulfilling: z.string(),
    })
    .nullish(),
  closeReasons: z.array(z.object({ class: z.object({ id: z.number(), description: z.string() }) })).nullish(),
  preferredInstallers: z
    .array(
      z.object({
        id: z.number(),
        kind: z.string(),
        name: z.string(),
        workTypes: z.array(z.string()),
        osPartnerId: z.number(),
      }),
    )
    .nullish(),
  designAnswers: z.object({}),
  tab: z
    .union([
      z.literal("closed"),
      z.literal("installed"),
      z.literal("contracts"),
      z.literal("proposals"),
      z.literal("qualified"),
    ])
    .nullable(),
  contractAt: z.string(),
  changeOrderNonrequestableReason: z
    .union([
      z.literal("only_mentor_or_seller"),
      z.literal("site_survey_not_scheduled"),
      z.literal("change_order_in_progress"),
      z.literal("permit_submitted"),
    ])
    .nullable(),
  canRequestChangeOrder: z.boolean(),
  activityLog: z.object({
    historicalData: z.array(ActivityLogDataSchema),
    priorityData: z.array(ActivityLogDataSchema),
  }),
  isSowConfirmationComplete: z.boolean(),
});

export const ProjectProviderLeadResponse = z.object({
  status: z.number(),
  body: ProjectSchema,
});

export const CreateTpoPricingWorksheetResponse = z.object({
  status: z.number(),
  body: TpoPricingWorksheetSchema,
});

export const GetTpoPricingWorksheetsResponse = z.object({
  status: z.number(),
  body: z.object({
    tpoPricingWorksheets: z.array(TpoPricingWorksheetSchema),
  }),
});
