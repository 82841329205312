export function isNotNull<T>(data: T): data is T {
  return data !== null;
}

export function isUndefined<T>(data: T | undefined): data is undefined {
  return data === undefined;
}

export function isNull<T>(data: T | null): data is null {
  return data === null;
}

export function isNullish<T>(data: T | null | undefined): data is null | undefined {
  return isNull(data) || isUndefined(data);
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}
export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}
